import {
  Dimensions,
  Platform,
  PlatformIOSStatic,
  StyleSheet,
} from 'react-native'
import colors from '../colors'
import platform from '../platform.json'
import { i18n } from '../translate'

import * as DeviceInfo from './DeviceInfo'
import * as file from './file'
import Keychain from './keychain'
import moment from 'moment/moment'
import { numeral } from '../numeral'
import KAZ_flag from '../../../assets/flags/kaz-flag.png'
import KGZ_flag from '../../../assets/flags/kgz-flag.png'
import OTHER_flag from '../../../assets/flags/other-flag.png'
import RUS_flag from '../../../assets/flags/rus-flag.png'
import TJK_flag from '../../../assets/flags/tjk-flag.png'
import UZB_flag from '../../../assets/flags/uzb-flag.png'
import constants from '../constants'

export * as safeAreaUtils from './safeAreaUtils'
export * as FetchBlob from './RNFetchBlob'

const { OS } = Platform
export const isIos = OS === 'ios'
export const isAndroid = OS === 'android'
export const isMobile = isIos || isAndroid
export const isWeb = OS === 'web'
export const APP_ENV = platform.ENVIRONMENT

const dimensions = Dimensions.get('window')

export const screenHeight = Math.round(dimensions.height)
export const screenWidth = Math.round(dimensions.width)

export const URL = platform.pay24.url.slice(0, -3)

export const composeStyle = (...args) => StyleSheet.flatten([args])

declare global {
  interface Window {
    PLATFORM?: { kiosk: boolean }
  }
}

export function i18nString(text?: string) {
  return text ? `${i18n.t(text)}` : ''
}

export function startsWithOne(string, values) {
  return values.some((element) => {
    return string.startsWith(element)
  })
}

export const dayOfWeek = (dt, lang = 'en') => {
  if (dt === undefined || dt === '' || dt === null) return ''
  return moment(dt).locale(lang).format('dddd').toLowerCase()
}

export function getImageUrl() {
  return platform.image?.url
}

export function getChargeImageUrl() {
  return `${platform.charge24.url}/user/get_avatar?`
}

export function getImagePath(path: string) {
  return getImageUrl() + '/' + path
}

export function getBucketUrl() {
  return platform.bucket.url
}

export function getBucketPath(filePath: string, folder?: string) {
  const splitted = filePath.split('/')
  let fileName: string | undefined
  let folderName: string | undefined
  if (splitted.length === 1) {
    fileName = splitted[0]
    folderName = folder || 'cheque'
  } else {
    folderName = splitted[0] || folder || 'cheque'
    fileName = splitted[1]
  }

  return `${getBucketUrl()}${folderName}&filename=${fileName}`
}

export const HEADER_HEIGHT = isWeb ? 80 : 60

export const amountFormat = (amount) => {
  numeral.locale('ru')
  if (amount === undefined || amount === '' || amount === null)
    return numeral(0).format('0,0')
  return numeral(parseFloat(amount)).format().replace(/,/g, ' ')
}

export const amountFormatCoins = (amount, currency?, decimal = true) => {
  const format = decimal ? '0,0.00' : '0,0'
  numeral.locale('ru')
  if (amount === undefined || amount === '' || amount === null)
    return `${numeral(0)?.format(format)} ${currency || ''}`
  return `${numeral(amount).format(format).replace(/,/g, '.')} ${
    currency ? currency : ''
  }`
}

export const amountFormatCurrency = (amount, decimal = true) => {
  const format = decimal ? '0,0.00' : '0,0'
  numeral.locale('ru')
  if (amount === undefined || amount === '' || amount === null)
    return `${numeral(0)?.format(format)} KGS`
  return `${numeral(parseFloat(amount))?.format(format)} KGS`
}

export const amountFormatCurrencySom = (amount, decimal = true) => {
  const format = decimal ? '0,0.00' : '0,0'
  if (amount === undefined || amount === '' || amount === null)
    return `${numeral(0)?.format(format)} c`
  return `${numeral(parseFloat(amount))?.format(format)} c`
}

export const dateTimeFormat = (dt) => {
  if (dt === undefined || dt === '' || dt === null) return ''
  return moment(dt).format(constants.DATE_TIME_FORMAT)
}

export const dateTimeFormatWithSeconds = (dt) => {
  if (dt === undefined || dt === '' || dt === null) return ''
  return moment(dt).format(constants.DATE_TIME_FORMAT_WITH_SECONDS)
}

export const dateFormat = (dt) => {
  if (dt === undefined || dt === '' || dt === null) return ''
  return moment(dt).format(constants.DATE_FORMAT)
}

export const PERIOD = { DAY: 'day', WEEK: 'week', MONTH: 'month' }

const bundleId = DeviceInfo.getBundleId()

export const isCharge24 = !!(() => {
  return (
    process.env.REACT_APP_ENTRY === 'charge' ||
    (bundleId && bundleId.includes('charge24'))
  )
})()

export const isEvion = !!(() => {
  return (
    process.env.REACT_APP_ENTRY === 'evion' ||
    (bundleId && bundleId.includes('evion'))
  )
})()

export const isPay = !!(() => {
  return (
    process.env.REACT_APP_ENTRY === 'pay' ||
    (bundleId && bundleId.includes('pay24.app'))
  )
})()

export const isKeep = !!(() => {
  return (
    process.env.REACT_APP_ENTRY === 'keep' ||
    (bundleId && bundleId.includes('keep'))
  )
})()

export const isDevMode = () => {
  return process.env.NODE_ENV === 'development'
}

export const getClient = () => {
  if (isAndroid) return '3'
  if (isIos || (OS as unknown as PlatformIOSStatic).isPad) return '5'
  return '1'
}

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export const dateFormatDB = (dt) => {
  if (dt === undefined || dt === '' || dt === null) return ''
  return moment(dt).format(constants.DB_DATE_FORMAT)
}

export const types2options = ([id, name]: [string, string]) => ({ id, name })

export function detectDevice() {
  const ua = navigator.userAgent
  const _isIos =
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ].includes(navigator.platform) ||
    (ua.includes('Mac') && 'ontouchend' in document)

  if (_isIos) return 'ios'
  if (/android/i.test(ua)) {
    return 'android'
  }
  return 'other'
}

export function isDecimal(price: number) {
  if (!price) return true
  const splitPrice = price.toFixed(2).split('.')
  const [_, right] = splitPrice
  return right[0] !== '0' || right[1] !== '0'
}

const sortedCountries = {
  Кыргызстан: 1,
  Казахстан: 2,
  Узбекистан: 3,
  Таджикистан: 4,
  Россия: 5,
}
export function getFormattedPrice(price: number) {
  if (!price) return 0
  const splitPrice = price.toFixed(2).split('.')
  const [left, right] = splitPrice
  const isInteger = right[0] === '0' && right[1] === '0'
  return isInteger ? left : splitPrice.join('.')
}

export const getTariffType = (tariff, t) => {
  if (tariff === 'power') {
    return 'кВт'
  }
  return t('minute')
}

export function getServiceCountries(services) {
  if (!services || services.length === 0) return []
  const countryList = services.map((item) =>
    item.data ? item.data.contact_data.country : item.contact_data.country,
  )
  const originalSet = new Set(countryList)
  const sortedSet: any = new Set(Object.keys(sortedCountries))
  const originalArray: any[] = [...originalSet]
  const otherCountries = originalArray.filter((o) => !sortedSet.has(o))
  const countries = originalArray
    .filter((c) => sortedSet.has(c))
    .sort((a, b) => (sortedCountries[a] || 10) - (sortedCountries[b] || 10))
  if (otherCountries.length > 0) {
    countries.push('Другие страны')
  }
  return countries
}

export function getCategoryCountries(categories) {
  if (!categories || categories.length === 0) return []
  const countryList = categories.map((item) => item.country)
  const originalSet = new Set(countryList)
  const sortedSet: any = new Set(Object.keys(sortedCountries))
  const originalArray: any[] = [...originalSet]
  const otherCountries = originalArray.filter((o) => !sortedSet.has(o))
  const countries = originalArray
    .filter((c) => sortedSet.has(c))
    .sort((a, b) => (sortedCountries[a] || 10) - (sortedCountries[b] || 10))
  if (otherCountries.length > 0) {
    countries.push('Другие страны')
  }
  return countries
}

export function filterServicesByCountry(service, selectedCountry) {
  const sortedList = Object.keys(sortedCountries)

  if (sortedList.includes(selectedCountry)) {
    return service.data
      ? service.data.contact_data.country === selectedCountry ||
          service.data.contact_data.country === 'Международные'
      : service.contact_data.country === selectedCountry ||
          service.contact_data.country === 'Международные'
  }

  return service.data
    ? !sortedList.includes(service.data.contact_data.country) ||
        service.data.contact_data.country === 'Международные'
    : !sortedList.includes(service.contact_data.country) ||
        service.contact_data.country === 'Международные'
}

export function filterCategoriesByCountry(category, selectedCountry) {
  const sortedList = Object.keys(sortedCountries)

  if (sortedList.includes(selectedCountry)) {
    return category
      ? category.country === selectedCountry ||
          category.country === 'Международные'
      : category.country === selectedCountry ||
          category.country === 'Международные'
  }

  return category
    ? !sortedList.includes(category.country) ||
        category.country === 'Международные'
    : !sortedList.includes(category.country) ||
        category.country === 'Международные'
}

export const COUNTRIES_OBJECT: Record<string, string> = {
  Кыргызстан: 'kyrgyzstan',
  Казахстан: 'kazakhstan',
  Узбекистан: 'uzbekistan',
  Таджикистан: 'tajikistan',
  Россия: 'russia',
  'Другие страны': 'other_countries',
}

export const SORT_OPTIONS = [
  { column: 'rec_date', value: 'Сначала старые', desc: false },
  { column: 'rec_date', value: 'Сначала новые', desc: true },
  { column: 'price', value: 'Сначала дешевле', desc: false },
  { column: 'price', value: 'Сначала дороже', desc: true },
]

export const fileToBase64 = async (f): Promise<string | ArrayBuffer | null> => {
  return new Promise((resolve, reject) => {
    try {
      const reader = new FileReader()
      reader.readAsDataURL(f)
      reader.onload = () => {
        const base64 = reader.result
        resolve(base64)
      }
    } catch (e) {
      reject(e)
    }
  })
}

export enum CommunicationType {
  PHONE = 'phone',
  TELEGRAM = 'telegram',
  EMAIL = 'email',
  WHATSAPP = 'whatsApp',
  INSTAGRAM = 'instagram',
}

export const SUPPLIER_DETAIL = {
  [CommunicationType.PHONE]: 'Телефон',
  [CommunicationType.TELEGRAM]: 'Telegram',
  [CommunicationType.EMAIL]: 'E-mail',
  [CommunicationType.WHATSAPP]: 'WhatsApp',
  [CommunicationType.INSTAGRAM]: 'Instagram',
}
export const COMMUNICATION_METHODS =
  Object.entries(SUPPLIER_DETAIL).map(types2options)

export const STATION_CAPACITY = {
  FAST: 'fast',
  SLOW: 'slow',
  ULTRA_FAST: 'ultra_fast',
}

const KGZ = 'Кыргызстан'
const KAZ = 'Казахстан'
const UZB = 'Узбекистан'
const TJK = 'Таджикистан'
const RUS = 'Россия'
const OTHER_COUNTRIES = 'Другие страны'

export const COUNTRY_FLAGS = {
  [KGZ]: KGZ_flag,
  [KAZ]: KAZ_flag,
  [UZB]: UZB_flag,
  [TJK]: TJK_flag,
  [RUS]: RUS_flag,
  [OTHER_COUNTRIES]: OTHER_flag,
}

export const COUNTRIES = [KGZ, KAZ, UZB, TJK, RUS, OTHER_COUNTRIES]

export const CURRENCIES = {
  KGS: {
    value: 'KGS',
    id: 'KGS',
    name: 'Кыргызский сом',
  },
  KZT: {
    value: 'KZT',
    id: 'KZT',
    name: 'Казахский тенге',
  },
  UZS: {
    value: 'UZS',
    id: 'UZS',
    name: 'Узбекский сум',
  },
  RUB: {
    value: 'RUB',
    id: 'RUB',
    name: 'Российский рубль',
  },
  USD: {
    value: 'USD',
    id: 'USD',
    name: 'Американский доллар',
  },
}

export const ACCOUNT_TYPES = [
  { id: null, name: 'all', icon: 'folder-outline', color: colors.metal },
  { id: 105, name: 'accounts', icon: 'bank-outline', color: colors.metal },
  {
    id: 90,
    name: 'wallets',
    icon: 'credit-card-outline',
    color: colors.metal,
  },
  { id: 106, name: 'cards', icon: 'wallet-outline', color: colors.metal },
]

export function flattenData(data, keys: any[] = []) {
  let buffer: any = []
  if (keys.length > 0) {
    Object.entries(data).forEach(
      ([key, value]) =>
        keys.includes(key) &&
        value &&
        value !== '\n' &&
        buffer.push(`${i18n.t(key)}: ${value}`),
    )
  } else {
    Object.entries(data).forEach(
      ([key, value]) =>
        value && value !== '\n' && buffer.push(`${i18n.t(key)}: ${value}`),
    )
  }
  return buffer.join('\n')
}

export const getStatusText = (status) => {
  if (status === 'pending') return i18nString('pending')
  if (status === 'success') return i18nString('successfully')
  if (status === 'fail' || status === 'error') return i18nString('fail')
  if (status === 'cancel') return i18nString('cancel_user')
  return i18nString('unknown')
}

export const getStatusIcon = (status) => {
  if (status === 'success' || status === 'Обработан' || status === 1)
    return 'check-circle-outline'
  if (
    status === 'fail' ||
    status === 'error' ||
    status === 'Аннулирован' ||
    status === 2 ||
    status === 3
  )
    return 'close-circle-outline'
  if (status === 'pending' || status === 0) return 'timer-outline'
  if (status === 'processing') return 'progress-clock'
  if (status === 'hold') return 'pause-circle-outline'
  return 'information-outline'
}

export const getStatusColor = (statusId, theme?) => {
  if (statusId === 'success' || statusId === 'Обработан' || statusId === 1)
    return colors.darkGreen
  if (
    statusId === 'error' ||
    statusId === 'fail' ||
    statusId === 'Аннулирован' ||
    statusId === 2 ||
    statusId === 3
  )
    return colors.red
  if (statusId === 'hold') return colors.secondary
  if (statusId === 'warning' || statusId === 'pending') return colors.orange
  if (statusId === 'info' || statusId === 'processing' || statusId === 0)
    return colors.primary
  return theme ? (theme === 'dark' ? colors.white : colors.black) : undefined
}

export class NativeFile {
  uri: string

  type: string

  name: string

  size: number

  constructor(uri: string, type: string, name: string, size: number) {
    if (!uri) {
      throw Error('Argument error. uri argument should be specified')
    }
    this.uri = uri
    this.type = type
    this.name = name ? name : uri.split('/').slice(-1)[0]
    this.size = size
  }
}

export enum ContractorType {
  FIZ = 'fiz',
  UR = 'ur',
  IP = 'ip',
}

export const CONTRACTOR_TYPES = [
  { id: ContractorType.FIZ, name: 'Физическое лицо' },
  { id: ContractorType.UR, name: 'Юридическое лицо' },
  { id: ContractorType.IP, name: 'Индивидуальный предприниматель' },
]

export function isEmail(value) {
  return /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)
}

export { platform, Keychain, DeviceInfo, file }
